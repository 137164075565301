$font-weight-bold: 600;
$font-weight-medium: 500;
$font-weight-regular: 400;

$primary-font-family: "Poppins", "-apple-system", "Segoe UI", "Roboto", "Helvetica Neue", "Arial", "sans-serif",
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

// For JS Imports
$exports: (
  fontWeightBold: $font-weight-bold,
  fontWeightMedium: $font-weight-medium,
  fontWeightRegular: $font-weight-regular,
);
